const PageCommon = {
  data: function() {
    return {
      dialogStates: {
        isValidOpened: false,
        isRevokeOpened: false,
        isRejectOpened: false
      }
    }
  },

  methods: {
    setDialogState: (statusName, statusValue) => {
      this.dialogStates[statusName] = statusValue;
    }
  }
}

export default PageCommon;