<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-2">
          <span class="overview-title">Appels à manifestation d'intérêt</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">{{ dropdownValue.name }}</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ amisTransmitted.length }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-xl-12">
        <div class="card no-gutter orders">
          <div class="card">
            <DataTable
              ref="dt"
              :value="amisTransmitted"
              data-key="id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              responsiveLayout="scroll"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="{totalRecords} avis de manifestation"
            >
              <template #header>
                <div
                  class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center"
                >
                  <h5 class="p-mb-2 p-m-md-0">Gestion des appels à manifestation d'intérêt</h5>

                  <span v-bind:style="{ width: '33%' }" class="">
                    <a style="margin-right: 15px" href="#" @click="exportAppelsManifestationsInterets($event)"
                      ><i class="pi pi-download"></i> Exporter</a
                    >
                    <InputText
                      v-bind:style="{ width: '68%' }"
                      v-model="entrepriseName"
                      placeholder="Rechercher une entreprise"
                    />
                    <Button
                      type="button"
                      @click="searching"
                      icon="pi pi-search"
                      class="p-button-success p-d-none p-d-md-inline-flex"
                    />
                  </span>
                </div>
                <br />
                <div style="float: right">
                  <h6 v-if="errorOnSearch" style="color: red">Aucune entreprise n'a été trouvée.</h6>
                  <h6 v-if="errorOnSearchSize" style="color: red">Le champ de recherche est vide.</h6>
                </div>
                <Dropdown id="state" v-model="dropdownValue" :options="dropdownValues" optionLabel="name"></Dropdown>
                <InputText
                  v-model="referenceKeyword"
                  v-bind:style="{ marginLeft: '20px' }"
                  placeholder="Rechercher par référence"
                />
              </template>
              <Column field="entrepriseName" header="Entreprise" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Entreprise</span>
                  {{ slotProps.data.entrepriseName }}
                </template>
              </Column>
              <Column
                field="projetMarche.intitule"
                header="Projet de marché"
                :sortable="true"
                bodyStyle="text-indent:1em"
              >
                <template #body="slotProps">
                  <span class="p-column-title">Projet de marché</span>
                  {{ slotProps.data.projetMarche.intitule }}
                </template>
              </Column>
              <Column field="reference" header="Référence" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Référence</span>
                  {{ slotProps.data.reference }}
                </template>
              </Column>
              <Column field="intitule" header="Intitulé" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Intitulé</span>
                  {{ slotProps.data.intitule }}
                </template>
              </Column>
              <Column field="objet" header="Objet" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Objet</span>
                  {{ slotProps.data.objet }}
                </template>
              </Column>
              <Column field="datePublication" header="Date de publication" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ getFormattedDate(slotProps.data.datePublication) }}</template>
              </Column>
              <Column field="dateLimite" header="Date limite" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">{{ getFormattedDate(slotProps.data.dateLimite) }}</template>
              </Column>
              <Column field="etat" header="Statut" :sortable="true" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title"></span>
                  <span v-if="slotProps.data.etat == 'VALIDE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'TRANSMIS'" :class="'product-badge status-lowstock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'CREE'" :class="'product-badge status-init'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'ATTRIBUE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                  <span v-if="slotProps.data.etat == 'RENVOYE'" :class="'product-badge status-outofstock'">{{
                    enumMap[slotProps.data.etat]
                  }}</span>
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Actions</span>
                  <Button
                    icon="pi pi-eye"
                    class="p-button-outlined p-button-rounded p-button-submit p-mr-2"
                    @click="openDetails(slotProps.data)"
                    v-tooltip.top="'Voir les détails'"
                  />
                  <Button
                    v-if="slotProps.data.etat == 'TRANSMIS'"
                    icon="pi pi-thumbs-up"
                    v-tooltip.top="'Valider'"
                    class="p-button-outlined p-button-rounded p-button-success p-mr-2"
                    @click="openValidationDialog(slotProps.data.id)"
                  />
                  <Button
                    v-if="slotProps.data.etat == 'TRANSMIS'"
                    icon="pi pi-thumbs-down"
                    v-tooltip.top="'Renvoyer pour correction'"
                    class="p-button-outlined p-button-rounded p-button-warning"
                    @click="openRevokeDialog(slotProps.data.id)"
                  />&nbsp;&nbsp;
                  <!--<Button
                    icon="pi pi-thumbs-down"
                    class="p-button-outlined p-button-rounded p-button-danger"
                    @click="openRejectionDialog(slotProps.data.id)"
                  />-->
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <Sidebar
      v-model:visible="dialogStates.detailsSelectedAmi"
      class="p-sidebar-md"
      position="right"
      @close="onUnsetSelectedAmiId"
    >
      <h4>Détails de l'appel à manifestation d'intérêt : {{ selectedEntity?.intitule }}</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          État : <b style="float: right">{{ enumMap[selectedEntity?.etat] }}</b>
        </li>
        <li class="detail-list-item">
          Type de régime :
          <b style="float: right">{{
            selectedEntity?.projetMarche?.regimes.map((regime) => enumMap[regime]).join(', ')
          }}</b>
        </li>
        <li v-if="selectedEntity?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right; color: red">{{ selectedEntity?.motifRenvoi }}</b>
        </li>
        <li v-if="!selectedEntity?.motifRenvoi" class="detail-list-item">
          Motif de renvoi : <b style="float: right">{{ 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Référence : <b style="float: right">{{ selectedEntity?.projetMarche.reference }}</b>
        </li>
        <li class="detail-list-item">
          Objet : <b style="float: right">{{ selectedEntity?.objet }}</b>
        </li>
        <li class="detail-list-item">
          Projet de marché associé : <b style="float: right">{{ selectedEntity?.projetMarche?.intitule }}</b>
        </li>
        <li class="detail-list-item">
          Entreprise : <b style="float: right">{{ selectedEntity?.focalPoint.entreprise?.name }}</b>
        </li>
        <li class="detail-list-item">
          Point focal : <b style="float: right">{{ selectedEntity?.focalPoint.name }}</b>
        </li>
        <li class="detail-list-item">
          Email : <b style="float: right">{{ selectedEntity?.focalPoint.email }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 1 : <b style="float: right">{{ selectedEntity?.focalPoint.mobile }}</b>
        </li>
        <li class="detail-list-item">
          Téléphone 2 : <b style="float: right">{{ selectedEntity?.focalPoint.phone || 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Site web : <b style="float: right">{{ selectedEntity?.focalPoint.entreprise.website || 'N/A' }}</b>
        </li>
        <li class="detail-list-item">
          Détails :
          <b style="float: right">
            <p style="font-size: 1rem">{{ selectedEntity?.details }}</p>
          </b>
        </li>
        <li class="detail-list-item">
          Domaine(s) métier(s) :
          <b>
            <div v-for="d in selectedEntity?.domaineMetiers" :key="d.id">- {{ d.libelle }}</div>
            <!-- <div v-for="d in domaineMetiers" :key="d.id">- {{ d.libelle }}</div> -->
          </b>
        </li>
        <li class="detail-list-item">
          Instructions :
          <b style="float: right">
            <p style="font-size: 1rem">{{ selectedEntity?.instructions }}</p>
          </b>
        </li>
        <li class="detail-list-item">
          Date de publication : <b style="float: right">{{ getFormattedDate(selectedEntity?.datePublication) }}</b>
        </li>
        <li class="detail-list-item">
          Date limite : <b style="float: right">{{ getFormattedDate(selectedEntity?.dateLimite) }}</b>
        </li>
        <li class="detail-list-item" v-show="selectedEntity.etat === 'ATTRIBUE'">
          Montant HT : <b style="float: right">{{ toCurrency(selectedEntity?.contrat?.montantHt) }}</b>
        </li>
      </ul>

      <br />
      <Panel header="Pièces jointes">
        <ul style="list-style: none; padding: 0">
          <li class="detail-list-item" v-for="(attachment, index) in selectedEntity?.piecesJointes" :key="index">
            <a style="cursor: pointer" @click="onDownload($event, attachment.urlPath)"
              ><img :src="getIconFromFileName(attachment.name)" width="25" />
              {{ getFileNameFromUrl(attachment.name) }}</a
            >
          </li>
        </ul>
      </Panel>

      <br />

      <Panel header="Attribué à" v-show="selectedEntity.etat === 'ATTRIBUE'">
        <DataTable :value="selectedEntity.entreprisesAttribuees" responsiveLayout="scroll">
          <Column field="name" header="Nom" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps">
              <span class="p-column-title">Nom</span>
              {{ slotProps.data.name }}
            </template>
          </Column>

          <Column field="pointFocal" header="Point focal" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps">
              <span class="p-column-title">Point focal</span>
              {{ slotProps.data.pointFocal.name }}
            </template>
          </Column>

          <Column field="email" header="Email" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps" :sortable="false">
              <span class="p-column-title">Email</span>
              {{ slotProps.data.pointFocal.email }}
            </template>
          </Column>

          <Column field="mobile" header="Téléphone" :sortable="false" bodyStyle="text-indent:1em">
            <template #body="slotProps" :sortable="false">
              <span class="p-column-title">Téléphone</span>
              {{ slotProps.data.pointFocal.mobile }}
            </template>
          </Column>
        </DataTable>
      </Panel>

      <br />
      <Button
        icon="pi pi-thumbs-down"
        v-if="selectedEntity?.etat == 'TRANSMIS'"
        label="Renvoyer pour correction"
        class="p-button-outlined p-button-rounded p-button-warning p-mr-2"
        @click="openRevokeDialog(selectedEntity?.id)"
      />
      &nbsp;&nbsp;
      <Button
        icon="pi pi-thumbs-up"
        v-if="selectedEntity?.etat == 'TRANSMIS'"
        label="Valider"
        class="p-button-outlined p-button-rounded p-button-success"
        @click="openValidationDialog(selectedEntity?.id)"
      />
    </Sidebar>

    <Dialog
      header="Correction de l'appel à manifestation d'intérêt"
      v-model:visible="dialogStates.isRevokeOpened"
      :style="{ width: '600px' }"
      :modal="true"
      class="p-fluid"
    >
      <label for="description">Objet(s) de la correction</label>
      <p>{{ entity?.motifRenvoi }}</p>
      <Textarea id="price" v-model="entity.motifRenvoi" required="true" rows="10" cols="25" />
      <template #footer>
        <Button
          label="Annuler"
          class="p-button p-component p-button-outlined p-button-danger"
          @click="handleHideDialogs"
        />
        <Button
          label="Renvoyer"
          :disabled="!entity?.motifRenvoi || entity?.motifRenvoi?.length === 0"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleRevocation"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.isValidOpened"
      header="Valider l'appel à manifestation d'intérêt"
      :style="{ width: '1000px' }"
      :modal="true"
      class="p-fluid"
    >
      <div class="prestataires">
        <div class="prestataires__all">
          <h3>Liste des potentiels prestataires</h3>
          <ul class="prestataires-list">
            <li class="prestataires-item item--titled">
              <input
                type="checkbox"
                :value="false"
                v-model="togglePresValueIds"
                @change="onTogglePrestatairesValuesIds"
              />
              Tout sélectionner
            </li>
            <li>
              <input
                style="width: 100%; margin: 10px 0; padding: 5px 5px"
                v-model="companyNameKeyword"
                type="text"
                placeholder="Recherche"
              />
            </li>
            <li>
              <select v-model="companyRankKeyword">
                <option value="">Choisir un profil</option>
                <option :value="tarif.rang" :key="tarif.id" v-for="tarif in tarifs">{{ tarif.adherant }}</option>
              </select>
            </li>
            <li
              class="prestataires-item"
              v-for="prestataire in selectedAmiPrestataires"
              :key="prestataire.id"
              :class="{ 'prestataires--selected': prestataire.id === selectedPrestataire?.id }"
            >
              <input type="checkbox" :value="prestataire.id" v-model="entity.entreprisesChoisisIds" />
              <span @click="seePrestataireDetails(prestataire.id)"
                >{{ prestataire.name }} - Créé le {{ prestataire.companyCreatedAt.split('-').reverse().join('/') }} -
                {{ enumMap[prestataire.rangSouscriptionEnCours] }}</span
              >
            </li>
          </ul>
        </div>
        <div class="prestataires__details" v-if="selectedPrestataire?.id">
          <h3 class="selected-prestataire__title">{{ selectedPrestataire?.name }}</h3>
          <ul class="selected-prestataire__list">
            <li class="selected-prestataire__item">
              Adresse: <b class="to--right">{{ selectedPrestataire?.address }}</b>
            </li>
            <li class="selected-prestataire__item">
              Numéro d'identification : <b class="to--right">{{ selectedPrestataire?.identificationNumber }}</b>
            </li>
            <li class="selected-prestataire__item">
              Registre de commerce : <b class="to--right">{{ selectedPrestataire?.tradeRegister }}</b>
            </li>
            <li class="selected-prestataire__item">
              Date de création : <b class="to--right">{{ getFormattedDate(selectedPrestataire?.companyCreatedAt) }}</b>
            </li>
            <li class="selected-prestataire__item">
              Email : <b class="to--right">{{ selectedPrestataire?.email }}</b>
            </li>
            <li class="selected-prestataire__item">
              Téléphone 1 : <b class="to--right">{{ selectedPrestataire?.mobile }}</b>
            </li>
            <li class="selected-prestataire__item">
              Téléphone 2 : <b class="to--right">{{ selectedPrestataire?.pointFocal.phone }}</b>
            </li>
            <li class="selected-prestataire__item">
              Site web : <b class="to--right">{{ selectedPrestataire?.www }}</b>
            </li>
            <li class="selected-prestataire__item">
              Profil : <b class="to--right">{{ enumMap[selectedPrestataire?.rangSouscriptionEnCours] }}</b>
            </li>
            <li class="selected-prestataire__item">
              Forme juridique : <b class="to--right">{{ selectedPrestataire?.formeJuridique?.libelle }}</b>
            </li>
            <!--<li class='selected-prestataire__item'>Domaines métiers: <b class='to--right'>{{ selectedPrestataire?.domaineMetiers.map(o => o.libelle).join(", ") }}</b></li>-->
            <li class="selected-prestataire__item">
              Pays : <b class="to--right">{{ selectedPrestataire?.pays?.name }}</b>
            </li>
          </ul>
        </div>
      </div>
      <template #footer>
        <Button
          label="Annuler"
          class="p-button p-component p-button-outlined p-button-danger"
          @click="handleHideDialogs()"
        />
        <Button
          label="Valider"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleValidation(entity)"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogStates.isRejectOpened"
      :style="{ width: '600px' }"
      header="Rejeter l'appel à manifestation d'intérêt"
      :modal="true"
      class="p-fluid"
    >
      <h3></h3>
      <h5>Êtes-vous sûr de vouloir rejeter définitivement l'appel à manifestation d'intérêt ?</h5>
      <h3></h3>
      <label for="description">Objet(s) du rejet</label>
      <p></p>
      <Textarea id="price" v-model="entity.motifRejet" required="true" rows="10" cols="25" />
      <template #footer>
        <Button
          label="Annuler"
          class="p-button p-component p-button-outlined p-button-danger"
          @click="handleHideDialogs()"
        />
        <Button
          label="Rejeter"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleRejection()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import PageCommon from '@/mixins/page-common';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DateFormatMixin from '@/mixins/date-format';
import EnumFormatMixin from '@/mixins/enum-format';
import FilesMixin from '../mixins/file-view';
import moment from 'moment';
import XLSX from 'xlsx';

export default {
  mixins: [PageCommon, DateFormatMixin, EnumFormatMixin, FilesMixin],
  data() {
    return {
      togglePresValueIds: false,
      selectedPrestataire: null,
      formSubmissionStates: {
        validation: false,
      },
      entity: {
        etat: null,
        motifRejet: null,
        motifRenvoi: null,
        entreprisesChoisisIds: [],
      },
      entrepriseName: '',
      referenceKeyword: '',
      companyNameKeyword: '',
      companyRankKeyword: '',
      dropdownValue: { name: 'Transmis', id: 1 },
      dropdownValues: [
        { name: 'Créé(s)', id: 0 },
        { name: 'Renvoyé(s)', id: 4 },
        { name: 'Transmis', id: 1 },
        { name: 'Validé(s)', id: 2 },
        { name: 'Attribué(s)', id: 7 },
        { name: 'Tous', id: 3 },
      ],
    };
  },

  watch: {
    companyNameKeyword: function (keyword) {
      this.searchByEnterpriseName({ keyword, rank: this.companyRankKeyword });
    },

    companyRankKeyword: function (rank) {
      this.searchByEnterpriseName({ keyword: this.companyNameKeyword, rank });
    },

    referenceKeyword: function (keyword) {
      this.filterAMI(this.dropdownValue.id, keyword);
    },

    selectedAmiPrestataires: function (prestataires) {
      if (prestataires && prestataires.length > 0) this.selectedPrestataire = prestataires[0];
    },

    dropdownValue: {
      handler: function (dropdownValue) {
        console.log({ dropdownValue });
        this.filterAMI(dropdownValue.id);
      },
    },

    selectedAmi: function (ami) {
      if (undefined !== ami)
        this.entity = {
          etat: ami.etat,
          motifRejet: ami.motifRejet,
          motifRenvoi: ami.motifRenvoi,
          entreprisesChoisisIds: ami.entreprisesChoisis.map((o) => o.id),
        };
    },
  },

  mounted() {
    // TODO. Soulager les requetes serveurs avec des parametres specifiques.
    this.fetchAllManifestationsInterets({ queryParams: { etat: 1 } });
    this.fetchTarifs();
  },

  computed: {
    ...mapGetters({
      selectedAmi: 'ami/getEntity',
      amisTransmitted: 'ami/getAllEntities',
      selectedAmiPrestataires: 'ami/getPrestatairesByName',
      selectedEntity: 'ami/getEntity',
      tarifs: 'tarifs/getAllEntities',
    }),
  },

  methods: {
    ...mapActions({
      fetchAllManifestationsInterets: 'ami/fetchAll',
      fetchPrestatairesById: 'ami/fetchPrestatairesById',
      createOrUpdate: 'ami/createOrUpdate',
      fetchTarifs: 'tarifs/fetchAll',
    }),
    ...mapMutations({
      searchByEnterpriseName: 'ami/setCompanyNameKeyword',
    }),

    async filterAMI(etat, reference = '') {
      const queryParams = etat == 3 ? { etat: [0, 1, 2, 4, 7] } : { etat };
      if (reference !== '') queryParams['reference'] = reference;
      if (etat === 3) {
        this.fetchAllManifestationsInterets({ queryParams });
      } else {
        this.fetchAllManifestationsInterets({ queryParams });
      }
    },

    async searching() {
      this.errorOnSearch = false;
      this.errorOnSearchSize = false;

      if (this.entrepriseName.length >= 1) {
        if (this.dropdownValue.id === 3) {
          this.fetchAllManifestationsInterets({
            queryParams: { entrepriseName: this.entrepriseName, etat: [0, 1, 2, 4, 7] },
          });
        } else {
          this.fetchAllManifestationsInterets({
            queryParams: { entrepriseName: this.entrepriseName, etat: this.dropdownValue.id },
          });
        }
      } else {
        this.errorOnSearchSize = true;
        setTimeout(() => (this.errorOnSearchSize = false), 3000);
        this.entrepriseName = '';
        this.dropdownValue = { name: 'Tous', id: 0 };
        this.fetchCompany(0);
      }
    },

    toCurrency(value) {
      if (value) return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
      return;
    },

    exportAppelsManifestationsInterets(event) {
      event.preventDefault();
      const fileName = `appels-manifestations-interets-${moment().format('DDMMYYYYHHmmss')}.xlsx`;
      const data = this.amisTransmitted.map((ami) => ({
        ID: ami.id,
        INSTRUCTION: ami.instructions,
        REFERENCE: ami.reference,
        INTITULE: ami.intitule,
        POINT_FOCAL: ami.focalPoint.name,
        POINT_TEL_1: ami.focalPoint.mobile,
        POINT_TEL_2: ami.focalPoint.phone,
        ENTREPRISE: ami.entrepriseName,
        ENTREPRISES_ATTRIBUEES: ami.entreprisesAttribuees.map((e) => e.name).join(' | '),
        ENTREPRISES_CHOISIES: ami.entreprisesChoisis.map((e) => e.name).join(' | '),
        PROJET_MARCHE: ami.projetMarche.intitule,
        OBJET: ami.objet,
        DATE_PUBLICATION: ami.datePublication,
        DATE_LIMITE: ami.dateLimite,
        DOMAINES_METIERS: ami.domaineMetiers.map((dm) => dm.libelle).join(' | '),
        STATUT: ami.etat,
        MOTIF_REJET: ami.motifRejet,
        MOTIF_RENVOI: ami.motifRenvoi,
        DETAILS: ami.details,
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'appels_manifestations_interets');
      XLSX.writeFile(wb, fileName);
    },

    handleValidation(entity) {
      const data = {
        entreprisesChoisisIds: entity.entreprisesChoisisIds,
        id: this.selectedAmi.id,
        etat: 'VALIDE', // TODO: put in AppConts.
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      this.dialogStates.isValidOpened = false;
    },

    seePrestataireDetails(id) {
      this.selectedPrestataire = this.selectedAmiPrestataires.find((prestataire) => prestataire.id === id);
    },

    handleRejection() {
      const data = {
        id: this.selectedAmi.id,
        motifRejet: this.entity.motifRejet,
        etat: 'REJETE',
        motifRenvoi: null,
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      this.dialogStates.isRejectOpened = false;
    },

    handleRevocation() {
      const data = {
        id: this.selectedAmi.id,
        motifRenvoi: this.entity.motifRenvoi,
        etat: 'RENVOYE',
        motifRejet: null,
      };
      this.createOrUpdate({ data, payload: { queryParams: { flag: 'SET_NEW_STATUS' } } });
      this.dialogStates.isRevokeOpened = false;
    },

    openDetails(ami) {
      this.$store.commit('ami/setSelectedEntityId', ami.id);
      this.detailsSelectedAmi = ami;
      this.dialogStates.detailsSelectedAmi = true;
    },

    openValidationDialog(id) {
      this.dialogStates.detailsSelectedAmi = false;
      this.$store.commit('ami/setSelectedEntityId', id);
      this.fetchPrestatairesById({ id });
      this.dialogStates.isValidOpened = true;
    },

    openRejectionDialog(id) {
      this.$store.commit('ami/setSelectedEntityId', id);
      this.dialogStates.isRejectOpened = true;
    },

    openRevokeDialog(id) {
      this.dialogStates.detailsSelectedAmi = false;
      this.$store.commit('ami/setSelectedEntityId', id);
      this.dialogStates.isRevokeOpened = true;
    },

    handleHideDialogs() {
      this.$store.commit('ami/setSelectedEntityId', null);
      this.dialogStates = {
        isRevokeOpened: false,
        isValidOpened: false,
        isRejectOpened: false,
      };
    },

    onTogglePrestatairesValuesIds() {
      this.togglePresValueIds = !!this.togglePresValueIds;
      if (this.togglePresValueIds === true)
        this.entity.entreprisesChoisisIds = this.selectedAmiPrestataires.map((prestataire) => prestataire.id);
      else this.entity.entreprisesChoisisIds = [];
    },
  },
};
</script>

<style lang="scss">
.width--500px {
  width: 500px !important;
}
.prestataires-list,
.selected-prestataire__list {
  list-style: none;
  padding: 0;
}
.prestataires-item {
  border-bottom: 1px solid #e7e7e7;
  padding: 5px;
  cursor: pointer;
}
.prestataires-item:hover {
  background-color: #f3f3f3;
}
.text-danger {
  color: #f44336 !important;
}
.prestataires {
  display: flex;
}
.prestataires--selected {
  background-color: #ddf0b2;
}
.prestataires__all {
  width: 60%;
}
.prestataires__details {
  width: 40%;
  border-left: 2px solid #e7e7e7;
  padding-left: 20px;
}
.selected-prestataire__item {
  list-style: none;
  padding: 0;
}

.to--right {
  float: right;
}

.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-init {
    background: #deddf5;
    color: #101b61;
  }
}
</style>
